import {
  ValidatorFn,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
export class CustomValidators {
  public static onlyText(): ValidatorFn {
    const pattern = '^[a-zA-Z s]*$';
    return Validators.pattern(pattern);
  }

  public static onlyTextWithHyphen(): ValidatorFn {
    const pattern = '^[a-zA-Z- s]*$';
    return Validators.pattern(pattern);
  }

  public static onlyNumber(): ValidatorFn {
    const pattern = '^\\d+$';
    return Validators.pattern(pattern);
  }

  public static telephoneumber(): ValidatorFn {
    const pattern = '^[0-9+ s]{3,15}$';
    return Validators.pattern(pattern);
  }

  public static onlyNumberWithPlus(): ValidatorFn {
    const pattern = '^[0-9+ s]*$';
    return Validators.pattern(pattern);
  }
  public static digitWithTwoDecimalPattern(c: FormControl) {
    const regex = /^\d+(\.\d{1,2})?$/;
    if (regex.test(c.value)) {
      return null;
    } else if (!c.value) {
      return null;
    }
    else {
      return { pattern: true };
    }
  }
  public static currency(c: FormControl) {
    const regex = /^(?:\d+|\d{1,2},(?:\d{2},)*\d{3})(?:\.\d{2})?$/;
    if (!c.value) {
      return null
    }
    if (regex.test(c.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  }
  public static isUrl(c: FormControl) {
    if (!c.value) {
      return null
    }
    const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
    if (urlRegex.test(c.value)) {
      return null
    } else {
      return { pattern: true };
    }

  }
  public static socialMediaUrlValidator(c: FormControl) {
    if (!c.value) {
      return null
    }
    const urlRegex = /^((http|https):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (urlRegex.test(c.value)) {
      return null
    } else {
      return { pattern: true };
    }

  }
  public static onlyAlphabetWithBracket(): ValidatorFn {
    const pattern = '^[a-zA-Z ()]*$'
    return Validators.pattern(pattern);
  }

  public static onlyAlphaNumeric(): ValidatorFn {
    const pattern = '^[a-zA-Z0-9 ]*$';
    return Validators.pattern(pattern);
  }
  public static onlyAlphaNumbericWithSlashHyphenSpace(c: FormControl) {
    const pattern = /^[a-zA-Z0-9- \\\/]*$/;
    if (pattern.test(c.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  }
  public static lowercaseValidator(c: FormControl) {
    const regex = /(?=.*?[a-z])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { lowercase: true };
    }
  }
  public static atLeastOneCharNumSpecValidator(): ValidatorFn {
    //minimum of 8 characters
    //at least 1 upper case and 1 lower case letter
    //at least 1 number
    //at least 1 symbol #?!@$%^&*-+_=|
    return (control: AbstractControl): ValidationErrors | null => {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$-%^&*+_=|])[A-Za-z\d#?!@$%^&*-+_=|]{8,}$/;
      const forbidden = regex.test(control.value);
      return forbidden || control.value === '' ? null : { atLeastOneCharNumSpec: true };
    };
  }
  public static uppercaseValidator(c: FormControl) {
    const regex = /(?=.*?[A-Z])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { uppercase: true };
    }
  }
  public static oneDigitValidator(c: FormControl) {
    const regex = /(?=.*?[0-9])/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { onedigit: true };
    }
  }
  public static oneSpecialCharValidator(c: FormControl) {
    const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (regex.test(c.value)) {
      return null;
    } else {
      return { specialChar: true };
    }
  }
  public static email(): ValidatorFn {
    // angular validator does not validate (dot) https://github.com/angular/angular/issues/16544#issuecomment-299199211

    return (control: AbstractControl): ValidationErrors | null => {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const forbidden = regex.test(control.value);
      return forbidden || control.value === '' ? null : { pattern: true };
    };
  }

  public static dateRangeValidator(control: FormControl) {
    const today = new Date();
    if (new Date(control.value).getTime() < today.getTime()) {
      return null;
    } else {
      return { dateError: true };
    }
  }
  public static customDateRangeValidator(control: FormControl) {
    const today = new Date();
    if (new Date(control.value.start_date).getTime() < today.getTime()) {
      return { dateError: true };
    }
    if (new Date(control.value.start_date).getTime() > new Date(control.value.end_date).getTime()) {
      return { dateError: true };
    }
    return null
  }

  public static safeValue(c: FormControl) {
    // a set of regex patterns a free test must pass for xss and sqi prevention
    const xssPattern = /((\%3C)|<)[^\n]+((\%3E)|>)/i;
    const sqiPattern = /((\%3D)|(=))[^\n]*((\%27)|(\')|(\-\-)|(\%3B)|(;))/i;
    if (xssPattern.test(c.value) || sqiPattern.test(c.value)) {
      return { pattern: true };
    } else {
      return null;
    }
  }
  public static onlyCharacterAndSpecialCharacters(): ValidatorFn {
    const pattern = '^[a-zA-Z !@#\$%\^\&*\)\(+=._-]+$';
    return Validators.pattern(pattern);
  }
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | any => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control?.get('password')?.value; // get password from our password form control
    const confirmPassword: string = control?.get('confirm_password')?.value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control?.get('confirm_password')?.setErrors({ match: true });
    }
  }
  static confirmEmailMatchValidator(control: AbstractControl) {
    const email_id: string = control?.get('email_id')?.value; // get password from our password form control
    const confirm_email_id: string = control?.get('confirm_email_id')?.value; // get password from our confirmPassword form control
    // compare is the password math
    if (email_id !== confirm_email_id) {
      // if they don't match, set an error in our confirmPassword form control
      control?.get('confirm_email_id')?.setErrors({ match: true });
    }
  }
  static emailMatchValidator(control: AbstractControl) {
    const password: string = control?.get('email_id')?.value;
    const confirmPassword: string = control?.get('confirm_email')?.value;
    if (password !== confirmPassword) {
      control?.get('confirm_email')?.setErrors({ match: true });
    }
  }
}

export function onlyTrue(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control?.value) {
      return null;
    } else {
      return { onlyTrue: true };
    }
  }
}

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(control.value) ? null : { invalidUrl: true };
  };
}

export function lowerCaseAlphaNumericUnderscore(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^[a-z0-9_]+$/.test(control.value);
    return isValid ? null : { invalidTemplateName: true };
  };
}




